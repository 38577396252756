import Vue from 'vue';
import App from './App';
import router from './vue/router';
import axios from '@/vue/plugins/axios';
import 'amfe-flexible/index.js'
import common from './common'   // 引入全局文件common.js
import store from './store'

Vue.prototype.$common = common;    // 全局变量
Vue.prototype.$store = store;    // 全局变量

Vue.use(axios);

new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
});
