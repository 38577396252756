import asyncLoader from 'core/async-loader';

export default [
  {
    path: '/login',
    name: 'login',
    components: {
      default: asyncLoader('login/index'),
    },
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/register/:params',
    name: 'register',
    props: true,
    component: asyncLoader('login/register')
  },

];
