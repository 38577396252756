const Tabbar = () =>
  import(/* webpackChunkName: "Tabbar" */ '@/vue/components/Tabbar/');
import asyncLoader from 'core/async-loader';

export default [
  {
    path: '/apply',
    name: 'apply',
    props:false,
    components: {
      default: asyncLoader('apply/index'),
      tabbar: Tabbar
    },
    meta: {
      keepAlive: false,
      login:false
    }
  },
  {
    path: '/apply/classDetail/:itemId',
    name: 'classDetail',
    props: true,
    meta: {
      keepAlive: false,
      login:false
    },
    component: asyncLoader('apply/classDetail')
  },
  {
    path: '/',
    name: 'submitOrder',
    props: false,
    meta: {
      keepAlive: false,
      login:true
    },
    component: asyncLoader('apply/submitOrder')
  },
  {
    path: '/',
    name: 'payOrder',
    props: false,
    meta: {
      keepAlive: false,
      login:true
    },
    component: asyncLoader('apply/payOrder')
  },
  {
    path: '/aliPay/:orderId',
    name: 'aliPay',
    props: true,
    meta: {
      keepAlive: true,
    },
    component: asyncLoader('apply/aliPay')
  },
  {
    path: '/apply/orderList',
    name: 'orderList',
    meta: {
      keepAlive: false
    },
    component: asyncLoader('apply/orderList'),
    props: route => route.query
  },
  {
    path: '/apply/paySuccess/:params',
    name: 'paySuccess',
    props: true,
    component: asyncLoader('apply/paySuccess')
  },

];
