<template>
  <div id="app">
    <keep-alive>
      <router-view
        class="view-router"
        v-if="$route.meta.keepAlive"
      ></router-view>
    </keep-alive>
    <router-view
      class="view-router"
      v-if="!$route.meta.keepAlive"
    ></router-view>
    <router-view name="tabbar"></router-view>
  </div>
</template>
<script>
import { getLocalStorage } from "@/core/utils/local-storage";

export default {
  computed: {
    themeType() {
      return this.$store.state.themeType;
    },
    router() {
      return this.$route;
    },
  },
  watch: {
    $route: {
      handler() {
        let val = getLocalStorage("themeColor");
        if(val){
          document.documentElement.style.setProperty(
            "--theme-color",
            val["themeColor"]
          );
        }
      },
      deep: true,
      immediate: true,
    },
    themeType(val) {
      if(val){
          document.documentElement.style.setProperty(
            "--theme-color",
            val
          );
        }
      // if (val == 0) {
      //   document.documentElement.style.setProperty(
      //     "--apply-header-color",
      //     "#ffedeb"
      //   );
      //   document.documentElement.style.setProperty("--tags-color", "#f5ddda");
      //   document.documentElement.style.setProperty("--theme-color", "#d6201f");
      //   document.documentElement.style.setProperty(
      //     "--theme-background",
      //     "linear-gradient(65deg, #d62423 0%, #d66564 100%)"
      //   );
      // } else if (val == 1) {
      //   document.documentElement.style.setProperty(
      //     "--apply-header-color",
      //     "#DFE8EF"
      //   );
      //   document.documentElement.style.setProperty(
      //     "--tags-color",
      //     "rgba(38, 145, 229, 0.12)"
      //   );

      //   document.documentElement.style.setProperty("--theme-color", "#2691E5");
      //   document.documentElement.style.setProperty(
      //     "--theme-background",
      //     "linear-gradient(135deg, #33BBF1 0%, #2691E5 100%)"
      //   );
      // }
    },
  },
};
</script>

<style>
/* body {
  font-size: 16px;
  background-color: #8d7878;
  -webkit-font-smoothing: antialiased;
} */

:root {
  --apply-header-color: #ffedeb;
  --tags-color: #f5ddda;
  --theme-color: #d6201f;
  --theme-background: linear-gradient(65deg, #d62423 0%, #d66564 100%);
}

* {
  margin: 0;
  padding: 0;
}
</style>
