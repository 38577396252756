import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      themeType: 0
  },
  mutations: {
    changeThemeType(state,val){
        state.themeType = val;
    }
  },

  getters:{
      
  }

})
