<template>
<div>
  <div class="lds-ball">
    <div></div>
  </div>
</div>
</template>

<script>
export default {};
</script>
