const Tabbar = () =>
  import(/* webpackChunkName: "Tabbar" */ '@/vue/components/Tabbar/');
import asyncLoader from 'core/async-loader';

export default [
  {
    path: '/',
    name: 'home',
    props:false,
    components: {
      default: asyncLoader('home/index'),
      tabbar: Tabbar
    },
    meta: {
      keepAlive: false,
      login:false
    }
  },
  {
    path: '/servers',
    name: 'servers',
    component: asyncLoader('home/servers'),
    props: route => route.query
  },
  {
    path: '/coach',
    name: 'coach',
    component: asyncLoader('home/coach'),
    props: route => route.query
  },
];
