import axios from "axios";
import qs from "qs";
import { Dialog, Toast } from "vant";
import Router from "@/vue/router/index";
import { removeLocalStorage } from "core/utils/local-storage";

const instance = axios.create({
  timeout: 10000,
  baseURL: process.env.NODE_ENV === "development" ? "/" : "/",
});

instance.interceptors.request.use(
  (config) => {
    if (config.method === "post" || config.method === "put") {
      config.data = qs.stringify(config.data);
    }
    if (!config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${
        window.localStorage.getItem("Authorization") || ""
      }`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

instance.interceptors.response.use(
  (res) => {
    if (res.data) {
      if (res.data.code) {
        switch (res.data.code) {
          case 200:
            return res.data;

          case 401:
            removeLocalStorage(["memberInfo"]);

            Router.push({ name: "login" });
            break;
          default:
            // Dialog.alert({
            //   message:
            //     res.data.message.length > 0 ? res.data.message : "网络异常",
            // });
            Toast(res.data.message.length > 0 ? res.data.message : "网络异常");
            return res.data;
        }
      } else {
        return res;
      }
    }
    return res;
  },
  (error) => {
    Dialog.alert({
      title: "警告",
      message: error.message,
    });
    return Promise.reject(error);
  }
);

const post = (url, data, config = {}) => instance.post(url, data, config);

const put = (url, data, config = {}) => instance.put(url, data, config);

const get = (url, params, config = {}) =>
  instance.get(url, {
    params,
    ...config,
  });

const deleteMethod = (url, config = {}) =>
  instance({
    url,
    method: "delete",
    ...config,
  });

export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast,
  },
  install(Vue) {
    Vue.prototype.$reqGet = get;
    Vue.prototype.$reqPost = post;
    Vue.prototype.$reqPut = put;
    Vue.prototype.$reqDel = deleteMethod;
  },
};
