import Vue from "vue";
import Router from "vue-router";
import { getLocalStorage } from "@/core/utils/local-storage";

import apply from "./apply";
import home from "./home";
import login from "./login";
import error from "./error";

Vue.use(Router);

const RouterModel = new Router({
  mode: "history",
  base: "/register/",
  routes: [...login, ...home, ...apply, ...error],
});

RouterModel.beforeEach((to, from, next) => {
  var { memberInfo } = getLocalStorage("memberInfo");

  if (to.meta.login && !memberInfo) {
    next({ name: "login" });
    return;
  }else if(memberInfo  && to.name == 'login'){
    next({ name: 'home'});
    return;
  }else{
    next();
  }
  
});

export default RouterModel;
