import asyncLoader from 'core/async-loader';


export default [
  {
    path: '/error',
    name: 'error',
    meta: {
      keepAlive: true
    },
    components: {
      default: asyncLoader('error/index'),
    }
  },

];
